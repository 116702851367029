import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section className='honbun'>
      <p>{`【補足】`}</p>
      <p>{`「『歌ってみた』では、オケが１つのファイルにまとめられているので、個別の楽器をEQできないのですが、どうすればいいですか？」`}</p>
      <p>{`という質問がありましたので、回答します。`}</p>
      <p>{`動画の説明通り、楽器をまとめてEQすればするほど、余計な帯域が変化します。`}<br parentName="p"></br>{`
`}{`この説明が意味するのは、「`}<strong parentName="p">{`可能な限り、楽器は個別にEQするのが原則`}</strong>{`」ということです。つまり、最低でも伴奏トラックとボーカルは、別々にEQするべきです。`}</p>
      <p>{`しかし、「伴奏が１トラックにまとめられているから、もう何もできない」ということではありません。必要があれば、伴奏トラックをEQすることで、副作用は生まれてしまうものの、しないよりはよっぽどマシな結果になる場合もあります。`}</p>
      <p>{`例えば、伴奏のキックに「鼓動」を感じない時や、ハットの「シャラシャラ」が感じないという時は、比較的副作用よりも効果の方が高いです。`}<br parentName="p"></br>{`
`}{`なぜなら、「鼓動」や「シャラシャラ」の帯域を出している楽器は、キックやハット以外にあまりなく、「他の楽器の帯域まで変化させてしまう」という副作用が生まれにくいからです。`}</p>
      <p>{`一方で、ギターやピアノなどの楽器は、ほとんどの楽器と帯域がかぶるので、伴奏トラックでまとめてEQすると副作用の方が大きくなりやすいです。下手をすると、泥沼にはまるので注意が必要です。`}</p>
      <p>{`この時、「オケをEQするべきか、しないべきか」の見極め方としては、プロのアーティストが出していて、ちゃんとプロのエンジニアがミックスしているオケなら、こちらでEQする必要はほぼありません。`}<br parentName="p"></br>{`
`}{`しかし、個人のボカロPなどが出しているオケは、正しくミックスされていない事が多いので、ある程度のEQが有効な場合もあります。`}</p>
      <p>{`ただし、全ては「`}<strong parentName="p">{`リファレンスとどのくらい違うか`}</strong>{`」で修正が必要かどうかを判断してください。全ての基準は、あくまでリファレンスです。極端な話、リファレンスと音が変わらなければ、一切のMIX作業は不要です。`}</p>
      <p>{`それ以前に、「歌ってみた」でよく来る質問は`}</p>
      <p>{`「`}<strong parentName="p">{`ボーカルが浮くので悩んでいる。どうEQすればよいのか？オケはまとめてEQするしかできなくても大丈夫か？`}</strong>{`」`}</p>
      <p>{`ですが、そもそもなぜボーカルが「浮く」のか、もう一度考えてください。`}<br parentName="p"></br>{`
`}{`ミックスの要素は、5つありました。EQは、その5つある要素のうちの1つでしかなく、優先度で言うと、冒頭の動画で話した通り、4番目になります。ですから、「浮き」に影響しているのは、割合で言えばボリュームやコンプの方がよほど大きいのです。`}<br parentName="p"></br>{`
`}{`例えば、一番多いのが「ボーカルのボリュームが大きすぎる」「コンプの設定が不適切で、ボーカルの強弱の範囲が、オケの強弱の範囲から飛び出している」などです。ですので、他のサイトによっては「マスターEQ（だけ）で解決しよう！」と説明しているところが多いようですが、それは的外れです。`}</p>
      <p>{`まとめになりますが、EQは「浮き」に対する要素の１つでしかありません。`}<br parentName="p"></br>{`
`}{`また、伴奏が一つにまとまっている場合でも、必要ならば、副作用は出るもののEQは可能です。`}<br parentName="p"></br>{`
`}{`その場合、操作する帯域によって難易度は変わります。鼓動やシャラシャラは比較的容易で、中域あたりは比較的困難になります。`}<br parentName="p"></br>{`
`}{`オケに対してのEQが必要かどうかは、オケがプロかアマか、そして何より「どのくらいリファレンスと差があるか」で判断してください。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      